import React from 'react';
import HTMLParser from 'html-react-parser';
import clsx from 'clsx';

import ButtonEditor from '../../LayoutComponents/ButtonEditor';
import { formColor } from '../../../../../helper';
import CustomSectionMedia from '../../LayoutComponents/CustomSectionMedia';

import * as styles from './styles.module.css';

const isPriceItem = (item) => ['PARAGRAPH/LIMITED', 'HEADINGS/HEADING-THREE'].includes(item?.type);

const CampaignLayout = ({
  section,
  themeData,
  buttons,
  filePathList,
  pagePathList,
  articlePathList,
  images,
  categoryPathList,
  sectionSlugs,
  isFirst,
}) => {
  const imageIndex = section.data.findIndex((elem) => elem.type === 'IMAGES/IMAGE');
  const image = section.data[imageIndex];
  image.content = {
    ...image.content,
    crop:
      typeof image.content.crop === 'boolean'
        ? image.content.crop
        : typeof section.styles.crop === 'boolean'
        ? section.styles.crop
        : true,
  };
  const full = section.styles.full === true;
  const elements = [];
  const btns = [];
  const price = [];

  let bottom = null;
  let imageElement = null;
  let priceDone = false;
  let priceAdded = false;

  const priceAlign = section.data.find(isPriceItem).align;

  section.data.map((item, index) => {
    let isBottom = false;
    let isPrice = false;
    if (item.type === 'BUTTONS/BUTTON') {
      if (item.active) {
        btns.push(
          <div key={`${section._id}_layout_${index}`} className={styles.btnWrapper}>
            <ButtonEditor
              buttons={buttons}
              themeData={themeData}
              data={item.content}
              pagePathList={pagePathList}
              articlePathList={articlePathList}
              filePathList={filePathList}
              categoryPathList={categoryPathList}
              sectionSlugs={sectionSlugs}
            />
          </div>,
        );
      }
    } else if (item.type === 'IMAGES/IMAGE') {
      if (item.active) {
        imageElement = (
          <CustomSectionMedia
            data={item.content}
            colors={themeData.colors}
            images={images}
            pagePathList={pagePathList}
            articlePathList={articlePathList}
            categoryPathList={categoryPathList}
            filePathList={filePathList}
            loadingStrategy={isFirst ? 'eager' : undefined}
            sectionSlugs={sectionSlugs}
          />
        );
      }
    } else {
      let result;
      if (item.type === 'COMPONENT') {
        if (item.active) {
          let style;
          if (item.styles.backgroundColor.active) {
            let { backgroundColor } = item.styles;
            if (item.styles.backgroundColor.solid === '' && item.styles.backgroundColor.gradient.from === '') {
              backgroundColor = { solid: '#ffffff' };
            }

            style = formColor(backgroundColor, false, item.styles.backgroundColor.opacity, undefined, themeData.colors);
          }

          const content = [];
          item.data.forEach((elem, ei) => {
            if (elem.type === 'IMAGES/IMAGE' && elem.active) {
              content.push(
                <CustomSectionMedia
                  key={`${section._id}_layout_${ei}_${index}`}
                  settings={{ align: false, width: [32, 156, 'px'] }}
                  className={styles.mediaSlot}
                  data={elem.content}
                  colors={themeData.colors}
                  images={images}
                  pagePathList={pagePathList}
                  articlePathList={articlePathList}
                  filePathList={filePathList}
                  categoryPathList={categoryPathList}
                  loadingStrategy={isFirst ? 'eager' : undefined}
                  sectionSlugs={sectionSlugs}
                />,
              );
            } else if (elem.type === 'PARAGRAPH/LIMITED' && elem.active) {
              content.push(
                <div
                  key={`${section._id}_layout_${ei}_${index}`}
                  className={item.align ? styles[`text${item.align}`] : null}
                  style={{ flex: 1 }}
                >
                  {HTMLParser(`<div>${elem.text}</div>`)}
                </div>,
              );
            }
          });
          elements.push(
            <div key={`${section._id}_Component_${index}_section`} className={styles.listWrapper} style={style}>
              {content}
            </div>,
          );
        }
      } else {
        let text;
        let style = '';
        if (item.type.startsWith('HEADINGS') && item.type !== 'HEADINGS/HEADING-THREE' && item.active) {
          if (item.type === 'HEADINGS/HEADING-TWO') {
            text = `<h2>${item.text}</h2>`;
            style = 'title';
          } else if (item.type === 'HEADINGS/HEADING-SIX') {
            text = `<span style="font-weight: 300">${item.text}</span>`;
            style = 'subtitle';
          }
        } else if (item.type.startsWith('PARAGRAPH') || item.type === 'HEADINGS/HEADING-THREE') {
          if (item.active) text = `<div>${item.text}</div>`;
          if (item.type === 'PARAGRAPH/PARAGRAPH' && item.active) {
            style = 'text';
          } else if (item.type === 'PARAGRAPH/SMALL' && item.active) {
            isBottom = true;
          } else if (item.type === 'PARAGRAPH/LIMITED' || item.type === 'HEADINGS/HEADING-THREE') {
            isPrice = true;
            if (item.type === 'HEADINGS/HEADING-THREE' && item.active) {
              text = `<h3>${item.text}</h3>`;
              style = 'priceHeading';
            }
            if (
              section.data[index + 1] &&
              ((section.data[index + 1].type !== 'PARAGRAPH/LIMITED' &&
                section.data[index + 1].type !== 'HEADINGS/HEADING-THREE') ||
                (section.data[index + 1].type === 'PARAGRAPH/LIMITED' && !section.data[index + 1].active))
            ) {
              // Next element is not a limited text or header or is an inactive limited text -> price texts are done
              priceDone = true;
            }
          }
        }

        if (text !== undefined) {
          let t = HTMLParser(text);
          if (isBottom) {
            t = (
              <div className={styles[`Small${themeData.typography.default.fontSize}`]}>
                {HTMLParser(`${item.text}`)}
              </div>
            );
          }
          result = (
            <div
              key={`${section._id}_layout_${index}`}
              className={clsx(styles[style], item.align && styles[`text${item.align}`])}
            >
              {t}
            </div>
          );
        }

        if (isPrice && result) {
          price.push(result);
        }
      }

      if (isBottom) bottom = result;
      else if (priceDone && !priceAdded) {
        priceAdded = true;
        elements.push(
          <div
            key={`${section._id}_Prices_${index}`}
            className={clsx(styles.priceWrapper, styles[`align${priceAlign}`])}
          >
            {price}
          </div>,
        );
      } else if (
        item.type !== 'PARAGRAPH/LIMITED' &&
        item.type !== 'HEADINGS/HEADING-THREE' &&
        item.type !== 'COMPONENTS/COMPONENT' &&
        item.active
      ) {
        elements.push(result);
      }
    }

    return null;
  });

  if (btns.length > 0) {
    elements.push(
      <div key={`${section._id}_Buttons`} className={styles.btnsWrapper}>
        {btns}
      </div>,
    );
  }

  return (
    <div className={`container ${styles[`campaignContainer${full ? 'Full' : ''}`]}`}>
      <div className={`row ${styles.wrapper}`} style={{ margin: 0 }}>
        <div className={`col-12 col-md-6 ${styles.leftWrapper}`}>
          <div className={styles.wrapper}>{elements}</div>
        </div>
        <div className={`col-12 col-md-6 ${styles.rightWrapper}`}>{imageElement}</div>
        <div className={`col-12 ${styles.disclaimerWrapper}`}>{bottom}</div>
      </div>
    </div>
  );
};

export default CampaignLayout;
